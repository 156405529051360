import "./style.css";

import theventures from "images/theventures.png";
import hitejinro from "images/hitejinro.png";
import blockchanger from "images/blockchanger.png";
import snuholdings from "images/snuholdings.png";

const DesktopFooter = () => {
  return (
    <div>
      <div id="partners">
        <div className="partner">
          <img src={snuholdings} alt="snuholdings" />
        </div>
        <div className="partner">
          <img src={theventures} alt="theventures" />
        </div>
        <div className="partner">
          <img src={hitejinro} alt="hitejinro" />
        </div>
        <div className="partner">
          <img src={blockchanger} alt="blockchanger" />
        </div>
        <div className="partner">
          <span>코나벤쳐파트너스(유)</span>
        </div>
      </div>
      <div id="copyright">
        <span>© 2023 Copyright :: 데브헤드 Co. Ltd.</span>
      </div>
    </div>
  );
};

export default DesktopFooter;
