import { useCallback } from "react";

import "./style.css";
import mobileStyle from "styles/mobile.module.css";

import CIImage1 from "images/ci-image1.png";
import CIImage2 from "images/ci-image2.png";
import { ReactComponent as TextLogoKo } from "images/text-logo-ko.svg";
import { ReactComponent as TextLogoEn } from "images/text-logo-en.svg";
import { ReactComponent as ILogo } from "images/i-logo.svg";
import { ReactComponent as OLogo } from "images/o-logo.svg";

const MobileCI = ({ alignLeft }) => {
  return (
    <div
      className={mobileStyle["mSection-container"]}
      style={{ marginBottom: 100 }}
    >
      <h2>company identity</h2>
      <div id="mCI-container" className={alignLeft ? "align-left" : ""}>
        <div id="mCI-image-block">
          <div id="mCI-image-header">
            <div />
            <span>DEVHEAD GREEN #5FF85C</span>
          </div>
          <div className="mCI-image-box">
            <img src={CIImage1} alt="CI" id="downloadable-logo" />
          </div>
          <div className="mCI-image-box">
            <img src={CIImage2} alt="CI" />
          </div>
        </div>
        <p className="mCI-description">
          <span>데브헤드의</span>
          <br />
          <br />
          <span>
            한글 초성{" "}
            <TextLogoKo
              style={{ marginLeft: 8, marginRight: 4, marginBottom: -2 }}
            />
            와
          </span>
          <br />
          <span>
            영어 초성{" "}
            <TextLogoEn
              style={{ marginLeft: 8, marginRight: 4, marginBottom: -2 }}
            />
            를
          </span>
          <br />
          <br />
          <span>나란히 놓고,</span>
        </p>
        <p className="mCI-description">
          <span>
            <font style={{ color: "#5FF85C" }}>빈틈없이 정확한 개발</font>을
            뜻하는{" "}
            <ILogo
              style={{ marginLeft: 4, marginRight: 4, marginBottom: -6 }}
            />
            과
          </span>
          <br />
          <span>
            <font style={{ color: "#5FF85C" }}>자유로운 발상</font>을 뜻하는{" "}
            <OLogo
              style={{ marginLeft: 4, marginRight: 4, marginBottom: -6 }}
            />
            을
          </span>
          <br />
          <br />
          <span>결합한 모양입니다.</span>
        </p>
      </div>
    </div>
  );
};

export default MobileCI;
