import "./style.css";
import desktopStyle from "styles/desktop.module.css";

import logo_detail from "images/ci_logo.svg";
import logo_description from "images/ci_description.svg";

const DesktopCI = () => {
  return (
    <div className="CI">
      <div
        className={desktopStyle["Section-title"]}
        style={{ alignSelf: "flex-start" }}
      >
        Company Identity
      </div>
      <img
        style={{ margin: "200px 0" }}
        src={logo_detail}
        width={1030}
        height={326}
        alt="logo"
      />
      <div className="CI-description">
        <img
          style={{ margin: "140px 0 140px 130px" }}
          src={logo_description}
          width={1025}
          height={334}
          alt="description"
        />
      </div>
    </div>
  );
};

export default DesktopCI;
