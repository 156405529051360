import { useState } from "react";
import { Link } from "react-scroll";

import "./style.css";

import logo_i from "images/logo-i.svg";
import logo_u from "images/logo-u.svg";
import MobileHome from "components/Mobile/MobileHome";
import MobilePartners from "components/Mobile/MobilePartners";
import MobileService from "components/Mobile/MobileService";
import MobileCI from "components/Mobile/MobileCI";
import MobileContact from "components/Mobile/MobileContact";

const MobilePage = () => {
  const [showMenu, setShowMenu] = useState(false);

  const onMenuToggle = () => {
    setShowMenu((prev) => !prev);
  };

  return (
    <div className="mMain">
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400;500&display=swap');
      </style>
      <div
        onClick={onMenuToggle}
        className="mMenuBtn"
        style={showMenu ? { transform: "rotate(-90deg)" } : null}
      >
        <img src={logo_i} alt="" width={21} />
        <img
          src={logo_u}
          alt=""
          width={21}
          style={{ marginTop: showMenu ? -1 : 3 }}
        />
      </div>
      <div
        className="mMenu"
        style={{
          transform: showMenu && "translateX(0)",
        }}
      >
        <Link
          className="mMenu-Link"
          activeClass="mMenu-active"
          onClick={() => setShowMenu(false)}
          to="home"
          spy
          smooth
          duration={400}
          offset={0}
        >
          INTRODUCTION
        </Link>
        <Link
          className="mMenu-Link"
          activeClass="mMenu-active"
          onClick={() => setShowMenu(false)}
          to="partners"
          spy
          smooth
          duration={400}
          offset={0}
        >
          PARTNERS
        </Link>
        <Link
          className="mMenu-Link"
          activeClass="mMenu-active"
          onClick={() => setShowMenu(false)}
          to="service"
          spy
          smooth
          duration={400}
          offset={0}
        >
          SERVICE
        </Link>
        <Link
          className="mMenu-Link"
          activeClass="mMenu-active"
          onClick={() => setShowMenu(false)}
          to="CI"
          spy
          smooth
          duration={400}
          offset={0}
        >
          CI
        </Link>
        <Link
          className="mMenu-Link"
          activeClass="mMenu-active"
          onClick={() => setShowMenu(false)}
          to="contact"
          spy
          smooth
          duration={400}
          offset={-100}
        >
          CONTACT
        </Link>
      </div>
      <div className={"Mobile-container" + (showMenu ? " align-left" : "")}>
        <div name="home">
          <MobileHome alignLeft={showMenu} />
        </div>
        <div name="partners">
          <MobilePartners alignLeft={showMenu} />
        </div>
        <div name="service">
          <MobileService alignLeft={showMenu} />
        </div>
        <div name="CI">
          <MobileCI alignLeft={showMenu} />
        </div>
        <div name="contact">
          <MobileContact alignLeft={showMenu} />
        </div>
        <div id="mCopyright">
          <span>© 2023 Copyright :: 데브헤드 Co. Ltd.</span>
        </div>
      </div>
    </div>
  );
};

export default MobilePage;
