import "./style.css";
import desktopStyle from "styles/desktop.module.css";

const DesktopContact = () => {
  return (
    <div className="Contact">
      <div className={desktopStyle["Section-title"]}>Contact</div>
      <div className="Contact-info">
        <div className="Contact-line">
          <p className="Contact-type">A</p>
          <p className="Contact-text">
            서울특별시 서초구 바우뫼로 162, 202호 데브헤드
          </p>
          <a
            target="blank"
            href="http://naver.me/G7yua3MG"
            className="Contact-btn"
          >
            지도보기
          </a>
        </div>
        <div className="Contact-line">
          <p className="Contact-type">P</p>
          <p className="Contact-text">(02) 3463-1105</p>
          <a href="tel:+82-2-3463-1105" className="Contact-btn">
            전화걸기
          </a>
        </div>
        <div className="Contact-line">
          <p className="Contact-type">E</p>
          <p className="Contact-text">help@devhead.co.kr</p>
          <a href="mailto:help@devhead.co.kr" className="Contact-btn">
            문의하기
          </a>
        </div>
      </div>
    </div>
  );
};

export default DesktopContact;
