import "./style.css";
import mobileStyle from "styles/mobile.module.css";

const MobileContact = ({ alignLeft }) => {
  return (
    <div className={mobileStyle["mSection-container"]}>
      <h2>contact</h2>
      <div id="mContact-container" className={alignLeft ? "align-left" : ""}>
        <div>
          <div>
            <div className="mContact-line">
              <span className="mContact-type">A</span>
              <span className="mContact-text">
                서울특별시 서초구 바우뫼로 162, 202호 데브헤드
              </span>
            </div>
            <a
              target="blank"
              href="http://naver.me/G7yua3MG"
              className="mContact-btn"
            >
              지도보기
            </a>
          </div>
          <div>
            <div className="mContact-line">
              <span className="mContact-type">P</span>
              <span className="mContact-text">(02) 3463-1105</span>
            </div>
            <a href="tel:+82-2-3463-1105" className="mContact-btn">
              전화걸기
            </a>
          </div>
          <div>
            <div className="mContact-line">
              <span className="mContact-type">E</span>
              <span className="mContact-text">help@devhead.co.kr</span>
            </div>
            <a href="mailto:help@devhead.co.kr" className="mContact-btn">
              문의하기
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileContact;
