import "./style.css";
import mobileStyle from "styles/mobile.module.css";

import theventures from "images/theventures.png";
import hitejinro from "images/hitejinro.png";
import blockchanger from "images/blockchanger.png";
import snuholdings from "images/snuholdings.png";

const MobilePartners = ({ alignLeft }) => {
  return (
    <div className={mobileStyle["mSection-container"]}>
      <h2>partners</h2>
      <div className={"mPartners" + (alignLeft ? " align-left" : "")}>
        <div>
          <div className="mPartners-logo">
            <img
              className="mPartners-logoimg"
              src={theventures}
              alt="the ventures"
            />
          </div>
          <div className="mPartners-logo">
            <img
              className="mPartners-logoimg"
              src={hitejinro}
              alt="hitejinro"
            />
          </div>
          <div className="mPartners-logo">
            <img
              className="mPartners-logoimg"
              src={blockchanger}
              alt="blockchanger"
            />
          </div>
          <div className="mPartners-logo">
            <span className="mPartners-logoimg">
              코나벤처
              <br />
              파트너스(유)
            </span>
          </div>
          <div className="mPartners-logo">
            <img
              className="mPartners-logoimg"
              src={snuholdings}
              alt="서울대기술지주"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePartners;
