import "./style.css";

import logo from "images/home-logo.png";
import fromio from "images/home-1and0.svg";

const DesktopHome = () => {
  return (
    <div className="Home">
      <div className="Home-logo">
        <div />
        <img src={logo} alt="Home logo" />
      </div>
      <div className="Home-description">
        <img src={fromio} alt="Home logo" />
        <span>즐거운 상상을 현실로 만드는</span>
        <span>데브헤드입니다.</span>
      </div>
    </div>
  );
};

export default DesktopHome;
