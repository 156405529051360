import "./style.css";
import mobileStyle from "styles/mobile.module.css";

import squizrunLogo from "images/squizrun-logo.svg";
import squizrun_play2 from "images/squizrun-play2.png";
import squizrun_play3 from "images/squizrun-play3.png";
import squizrun_play4 from "images/squizrun-play4.png";

import adotLogo from "images/logo-adot.svg";

const MobileService = ({ alignLeft }) => {
  return (
    <div
      className={mobileStyle["mSection-container"]}
      style={{ marginBottom: 100 }}
    >
      <h2>Service</h2>
      <div id="mService-container" className={alignLeft ? "align-left" : ""}>
        <div id="mService-logo-block">
          <div className="mService-description-title">
            <span style={{ width: "86%" }}>실시간 스포츠 퀴즈</span>
          </div>
          <div id="mService-logo-image-box">
            <img src={squizrunLogo} alt="SQUIZ RUN" />
          </div>
          <div className="mService-description-content">
            <span style={{ width: "86%" }}>
              야구 경기를 보며 15초 내에 간단한 퀴즈의
              <br />
              정답을 고르는 색다른 야구 컨텐츠!
            </span>
          </div>
        </div>
        <div className="mService-capture" style={{ marginTop: 50 }}>
          <div className="mService-play-image">
            <img src={squizrun_play2} alt="squizrun play" />
          </div>
          <div className="mService-description">
            <div className="mService-description-title">
              <span>TV 야구 중계와 함께 즐거운 퀴즈 풀이</span>
            </div>
            <div className="mService-description-content">
              <span>
                경기를 보면서 그 상황을 예측하는 퀴즈를 풉니다
                <br />
                <img src={adotLogo} alt="ADOT" />
                에이닷 캐릭터를 활용해 귀여운 화면은 덤!
              </span>
            </div>
          </div>
        </div>
        <div className="mService-capture">
          <div className="mService-play-image">
            <img src={squizrun_play3} alt="squizrun play" />
          </div>
          <div className="mService-description">
            <div className="mService-description-title">
              <span>실시간 채팅으로 남는 시간 없이 즐기기</span>
            </div>
            <div className="mService-description-content">
              <span>
                퀴즈가 없는 시간동안
                <br />
                다양한 응원아이템이나 슈퍼챗으로
                <br />
                함께 즐기는 야구경기!
              </span>
            </div>
          </div>
        </div>
        <div id="mService-last-capture">
          <div
            id="mService-background"
            style={{ backgroundImage: `url(${squizrun_play4})` }}
          />
          <div className="mService-description">
            <div className="mService-description-title">
              <span>내 취향대로 꾸미는 퀴즈 화면</span>
            </div>
            <div className="mService-description-content">
              <span>
                퀴즈를 맞히면 지급되는 스퀴즈볼로
                <br />
                상점에서 내가 원하는 대로 게임을 꾸며보세요.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileService;
