import { isMobile } from "react-device-detect";

import MobilePage from "pages/MobilePage";
import DesktopPage from "pages/DesktopPage";

const App = () => {
  if (isMobile) {
    return <MobilePage />;
  }
  return <DesktopPage />;
};

export default App;
