import "./style.css";
import desktopStyle from "styles/desktop.module.css";

import squizrun_play1 from "images/squizrun-play1.png";
import squizrun_play2 from "images/squizrun-play2.png";
import squizrun_play3 from "images/squizrun-play3.png";
import squizrun_play4 from "images/squizrun-play4.png";
import star_logo1 from "images/star-logo1.svg";
import star_logo2 from "images/star-logo2.svg";
import star_logo3 from "images/star-logo3.svg";
import squizrunLogo from "images/squizrun-logo.svg";
import adotLogo from "images/logo-adot.svg";
import playerLeft from "images/player-left.svg";
import playerCenter from "images/player-center.svg";
import playerRight from "images/player-right.svg";

const DesktopService = () => {
  return (
    <div className="Service">
      <div className={desktopStyle["Section-title"]}>Service</div>
      <div className="Service-page top-content" style={{ marginTop: 0 }}>
        <div className="Service-capture">
          <img src={squizrun_play1} alt="squizrun play" />
        </div>
        <div
          className="Service-description"
          style={{ paddingTop: 250, marginLeft: 200 }}
        >
          <h4>실시간 스포츠 퀴즈</h4>
          <img className="Squizrun-text" src={squizrunLogo} alt="SQUIZ RUN" />
          <p className="Service-detail">
            야구 경기를 보며 15초 내에 간단한 퀴즈의
            <br />
            정답을 고르는 색다른 야구 컨텐츠!
          </p>
        </div>
      </div>
      <div className="Service-page">
        <div className="Service-description">
          <img
            src={star_logo1}
            alt="STAR"
            className="Service-star-logo"
            style={{ width: 70, height: 78, top: -100, left: -35 }}
          />
          <h5>TV 야구 중계와 함께 즐거운 퀴즈 풀이</h5>
          <p className="Service-detail">
            경기를 보면서 그 상황을 예측하는 퀴즈를 풉니다.
            <br />
            <img
              src={adotLogo}
              alt="ADOT"
              style={{ marginBottom: -2, marginRight: 10 }}
            />
            에이닷 캐릭터를 활용해 귀여운 화면은 덤!
          </p>
          <div className="Service-players">
            <div className="Player-block">
              <img src={playerLeft} width={120} height={140} alt="PLAYER" />
              <span>실시간</span>
            </div>
            <div className="Player-block">
              <img src={playerCenter} width={93} height={99} alt="PLAYER" />
              <span>자동출제</span>
            </div>
            <div className="Player-block">
              <img src={playerRight} width={123} height={99} alt="PLAYER" />
              <span>자동채점</span>
            </div>
          </div>
        </div>
        <img
          src={squizrun_play2}
          className="Service-capture"
          alt="squizrun play"
        />
      </div>
      <div className="Service-page">
        <img
          src={squizrun_play3}
          className="Service-capture"
          alt="squizrun play"
          style={{ marginLeft: -30 }}
        />
        <div
          className="Service-description"
          style={{ paddingTop: 100, marginRight: 100 }}
        >
          <img
            src={star_logo2}
            alt="STAR"
            className="Service-star-logo"
            style={{ width: 108, height: 91, bottom: -60, right: -50 }}
          />
          <h5 style={{ textAlign: "right" }}>
            실시간 채팅으로 남는 시간 없이 즐기기
          </h5>
          <p className="Service-detail" style={{ textAlign: "right" }}>
            퀴즈가 없는 시간동안
            <br />
            다양한 응원아이템이나 슈퍼챗으로
            <br />
            함께 즐기는 야구경기!
          </p>
        </div>
      </div>
      <div
        id="Service-last-page"
        style={{ backgroundImage: `url(${squizrun_play4})` }}
      >
        <div className="Service-description">
          <img
            src={star_logo3}
            alt="STAR"
            className="Service-star-logo"
            style={{ width: 28, height: 29, top: -70, left: 0 }}
          />
          <h5>내 취향대로 꾸미는 퀴즈 화면</h5>
          <p className="Service-detail">
            퀴즈를 맞히면 지급되는 스퀴즈볼로
            <br />
            상점에서 내가 원하는 대로 게임을 꾸며보세요.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DesktopService;
