import { Link } from "react-scroll";

import "./style.css";

import logo from "images/logo.png";

import Home from "components/Desktop/DesktopHome";
import Service from "components/Desktop/DesktopService";
import CI from "components/Desktop/DesktopCI";
import Contact from "components/Desktop/DesktopContact";
import Footer from "components/Desktop/DesktopFooter";

const DesktopPage = () => {
  return (
    <div className="Main">
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400;500&display=swap');
      </style>
      <div className="Main-header">
        <div className="Header-content">
          <Link
            className="Menu-link"
            activeClass="Menu-active"
            to="home"
            spy
            smooth
            duration={400}
            offset={-150}
          >
            <img src={logo} className="Header-logo" alt="logo" />
          </Link>
          <div className="Header-menu">
            <Link
              className="Menu-link"
              activeClass="Menu-active"
              to="service"
              spy
              smooth
              duration={400}
              offset={-150}
            >
              SERVICE
            </Link>
            <Link
              className="Menu-link"
              activeClass="Menu-active"
              to="ci"
              spy
              smooth
              duration={400}
              offset={-150}
            >
              CI
            </Link>
            <Link
              className="Menu-link"
              activeClass="Menu-active"
              to="contact"
              spy
              smooth
              duration={400}
              offset={-150}
            >
              CONTACT
            </Link>
          </div>
        </div>
      </div>
      <div className="Main-content">
        <div name="home">
          <Home />
        </div>
        <div name="service">
          <Service />
        </div>
        <div name="ci">
          <CI />
        </div>
        <div name="contact">
          <Contact />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default DesktopPage;
