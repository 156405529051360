import "./style.css";

import titleLogo from "images/mobile-home-logo.png";
import fromio from "images/home-1and0.svg";

const MobileHome = ({ alignLeft }) => {
  return (
    <div id="mHome-container" className={alignLeft ? "align-left" : ""}>
      <div className="mHome-logo">
        <img src={titleLogo} alt="logo" />
      </div>
      <div className="mHome-description">
        <img src={fromio} width={105} alt="Home logo" />
        <span>즐거운 상상을 현실로 만드는</span>
        <span>데브헤드입니다.</span>
      </div>
    </div>
  );
};

export default MobileHome;
